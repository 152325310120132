define('ember-burger-menu/components/burger-menu', ['exports', 'ember', 'ember-burger-menu/templates/components/burger-menu', 'ember-burger-menu/computed/style-for', 'ember-burger-menu/mixins/swipe-support', 'ember-burger-menu/-private/state', 'ember-lifeline/mixins/dom', 'ember-burger-menu/utils/is-fastboot'], function (exports, _ember, _emberBurgerMenuTemplatesComponentsBurgerMenu, _emberBurgerMenuComputedStyleFor, _emberBurgerMenuMixinsSwipeSupport, _emberBurgerMenuPrivateState, _emberLifelineMixinsDom, _emberBurgerMenuUtilsIsFastboot) {
  var $ = _ember['default'].$;
  var on = _ember['default'].on;
  var run = _ember['default'].run;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  exports['default'] = _ember['default'].Component.extend(_emberLifelineMixinsDom['default'], _emberBurgerMenuMixinsSwipeSupport['default'], {
    layout: _emberBurgerMenuTemplatesComponentsBurgerMenu['default'],
    classNames: ['ember-burger-menu'],
    classNameBindings: ['open:is-open', 'translucentOverlay', 'animationClass', 'position'],
    attributeBindings: ['style'],

    translucentOverlay: true,
    dismissOnClick: true,
    dismissOnEsc: true,
    gesturesEnabled: true,

    state: computed(function () {
      return _emberBurgerMenuPrivateState['default'].create();
    }).readOnly(),

    open: alias('state.open'),
    locked: alias('state.locked'),
    position: alias('state.position'),
    width: alias('state.width'),
    animation: alias('state.animation'),
    itemAnimation: alias('state.itemAnimation'),
    customAnimation: alias('state.customAnimation'),

    style: (0, _emberBurgerMenuComputedStyleFor['default'])('container').readOnly(),

    animationClass: computed('state.styles.animation', function () {
      return 'bm--' + this.get('state.styles.animation');
    }).readOnly(),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      run.cancel(this._setupEventsTimer);
    },

    setupEvents: on('didReceiveAttrs', observer('open', 'locked', function () {
      if ((0, _emberBurgerMenuUtilsIsFastboot['default'])()) {
        return;
      }

      var methodName = this.get('open') && !this.get('locked') ? '_setupEvents' : '_teardownEvents';
      this._setupEventsTimer = run.scheduleOnce('afterRender', this, methodName);
    })),

    _setupEvents: function _setupEvents() {
      var elementId = this.get('elementId');

      if (this.get('dismissOnClick')) {
        this.addEventListener($('body'), 'click.' + elementId, this.onClick);
        this.addEventListener($('body'), 'touchstart.' + elementId, this.onClick);
      }

      if (this.get('dismissOnEsc')) {
        this.addEventListener(document, 'keyup.' + elementId, this.onKeyup);
      }
    },

    _teardownEvents: function _teardownEvents() {
      var elementId = this.get('elementId');

      this.removeEventListener($('body'), 'click.' + elementId, this.onClick);
      this.removeEventListener($('body'), 'touchstart.' + elementId, this.onClick);
      this.removeEventListener(document, 'keyup.' + elementId, this.onKeyup);
    },

    onClick: function onClick(e) {
      var elementId = this.get('elementId');

      // Close the menu if clicked outside of it
      if ($(e.target).closest('#' + elementId + ' .bm-menu').length === 0) {
        this.get('state.actions').close();
      }
    },

    onKeyup: function onKeyup(e) {
      if (e.keyCode === 27) {
        this.get('state.actions').close();
      }
    },

    onSwipe: function onSwipe(direction, target) {
      var position = this.get('position');
      var open = this.get('open');
      var gesturesEnabled = this.get('gesturesEnabled');
      var isMenuSwipe = $(target).closest('.bm-menu').length > 0;

      if (!gesturesEnabled) {
        return;
      }

      if (open && isMenuSwipe && position === direction) {
        this.get('state.actions').close();
      } else if (!open && position !== direction) {
        this.get('state.actions').open();
      }
    }
  });
});