define('ember-elsewhere/components/to-elsewhere', ['exports', 'ember', 'ember-elsewhere/templates/components/to-elsewhere'], function (exports, _ember, _emberElsewhereTemplatesComponentsToElsewhere) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberElsewhereTemplatesComponentsToElsewhere['default'],
    service: _ember['default'].inject.service('ember-elsewhere'),
    tagName: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this.get('service').show(_ember['default'].guidFor(this), this.get('named'), this.get('send'));
    },
    willDestroyElement: function willDestroyElement() {
      this.get('service').clear(_ember['default'].guidFor(this));
    }

  });
});