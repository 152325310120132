define('ember-form-for/components/form-controls/button', ['exports', 'ember', 'ember-one-way-controls/-private/dynamic-attribute-bindings', 'ember-form-for/templates/components/form-controls/button'], function (exports, _ember, _emberOneWayControlsPrivateDynamicAttributeBindings, _emberFormForTemplatesComponentsFormControlsButton) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;

  var Button = Component.extend(_emberOneWayControlsPrivateDynamicAttributeBindings['default'], {
    layout: _emberFormForTemplatesComponentsFormControlsButton['default'],
    tagName: 'button',
    type: 'button',
    attributeBindings: ['type'],
    config: service('ember-form-for/config'),

    NON_ATTRIBUTE_BOUND_PROPS: ['click'],

    init: function init() {
      this._super.apply(this, arguments);

      var type = get(this, 'type');
      var buttonClasses = get(this, 'config.' + type + 'Classes');
      var classNames = get(this, 'classNames');
      set(this, 'classNames', (classNames || []).concat(buttonClasses));
    }
  });

  Button.reopenClass({
    positionalParams: ['label']
  });

  exports['default'] = Button;
});