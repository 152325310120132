define('ember-burger-menu/-private/state', ['exports', 'ember', 'ember-burger-menu/animations'], function (exports, _ember, _emberBurgerMenuAnimations) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Object.extend({
    open: false,
    locked: false,
    width: 300,
    position: 'left',
    animation: 'slide',

    minSwipeDistance: 150,
    maxSwipeTime: 300,

    itemAnimation: null,
    customAnimation: null,

    styles: computed('animation', 'itemAnimation', 'customAnimation', function () {
      var animation = this.get('animation');
      var itemAnimation = this.get('itemAnimation');
      var customAnimation = this.get('customAnimation');

      return (0, _emberBurgerMenuAnimations['default'])(customAnimation || animation, itemAnimation).create();
    }).readOnly(),

    actions: computed(function () {
      var _this = this;

      return {
        open: function open() {
          return !_this.get('locked') && _this.set('open', true);
        },
        close: function close() {
          return !_this.get('locked') && _this.set('open', false);
        },
        toggle: function toggle() {
          return !_this.get('locked') && _this.toggleProperty('open');
        }
      };
    }).readOnly()
  });
});