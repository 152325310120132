define('ember-burger-menu/animations/menu-item/push', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    itemAnimation: 'push',

    menuItem: function menuItem(open, width, right, index) {
      return {
        transform: open ? '' : right ? 'translate3d(' + (index + 1) * 500 + 'px, 0, 0)' : 'translate3d(-' + (index + 1) * 500 + 'px, 0, 0)'
      };
    }
  });
});