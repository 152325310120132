define('ember-local-settings/adapters/local-storage', ['exports', 'ember', 'ember-local-settings/adapters/web-storage'], function (exports, _ember, _emberLocalSettingsAdaptersWebStorage) {
  var NAME_KEY = _ember['default'].NAME_KEY;

  /**
   * Adapter that stores values in HTML5 local storage.
   *
   * @class LocalStorageAdapter
   * @extends WebStorageAdapter
   */
  var LocalStorageAdapter = _emberLocalSettingsAdaptersWebStorage['default'].extend({
    /**
     * @property storageAPI
     * @private
     */
    storageAPI: 'localStorage'
  });
  LocalStorageAdapter[NAME_KEY] = 'LocalStorageLocalSettingsAdapter';

  exports['default'] = LocalStorageAdapter;
});