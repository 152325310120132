define('ember-local-settings/local-settings-interface', ['exports', 'ember', 'ember-local-settings/mixins/settings-interface'], function (exports, _ember, _emberLocalSettingsMixinsSettingsInterface) {
  var NAME_KEY = _ember['default'].NAME_KEY;

  /**
   * An object implementing a settings interface.
   *
   * @extends Ember.Object
   * @uses SettingsInterfaceMixin
   */
  var LocalSettingsInterface = _ember['default'].Object.extend(_emberLocalSettingsMixinsSettingsInterface['default'], {
    /**
     * Create a "branch" -- a futher prefixed settings interface. The prefix is
     * appended to this service's prefix.
     *
     * @param prefix
     * @returns {*} settings interface
     */
    createBranch: function createBranch(prefix) {
      return LocalSettingsInterface.create({
        serializer: this.get('serializer'),
        adapter: this.get('adapter'),
        prefix: this.get('prefix') + prefix
      });
    }
  });
  LocalSettingsInterface[NAME_KEY] = "LocalSettingsInterface";

  exports['default'] = LocalSettingsInterface;
});