define("ember-local-settings/serializers/json", ["exports", "ember"], function (exports, _ember) {
  var NAME_KEY = _ember["default"].NAME_KEY;

  /**
   * A serializer that serializes values to JSON strings.
   *
   * @class JsonSerializer
   * @extends Ember.Object
   */
  var JsonSerializer = _ember["default"].Object.extend({
    /**
     * Serialize a value
     *
     * @param value
     * @returns {String} JSON string
     */
    serialize: function serialize(value) {
      if (value === null || value === undefined) {
        return null;
      }
      return JSON.stringify(value);
    },

    /**
     * Deserialize a value
     *
     * @param {String} JSON string
     * @returns {*} value
     */
    deserialize: function deserialize(value) {
      if (value === null || value === undefined) {
        return null;
      }
      return JSON.parse(value);
    }
  });
  JsonSerializer[NAME_KEY] = "JsonLocalSettingsSerializer";

  exports["default"] = JsonSerializer;
});