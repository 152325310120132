define('ember-form-for/mixins/form-field', ['exports', 'ember', 'ember-form-for/utils/titlecase'], function (exports, _ember, _emberFormForUtilsTitlecase) {
  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var guidFor = _ember['default'].guidFor;
  var set = _ember['default'].set;
  var typeOf = _ember['default'].typeOf;
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    instrumentDisplay: '{{form-field}}',

    didReceiveAttrs: function didReceiveAttrs() {
      assert(this.instrumentDisplay + ' requires propertyName to be set', typeOf(get(this, 'propertyName')) === 'string');

      var objectType = typeOf(get(this, 'object'));
      assert(this.instrumentDisplay + ' requires object to be set', objectType === 'object' || objectType === 'instance');

      this._setupLabel();

      this._super.apply(this, arguments);
    },

    identifier: computed('object', 'propertyName', 'value', function () {
      return this._identifier();
    }),

    _identifier: function _identifier() {
      return guidFor(get(this, 'object')) + '_' + get(this, 'propertyName');
    },

    _setupLabel: function _setupLabel() {
      set(this, 'label', get(this, 'label') || (0, _emberFormForUtilsTitlecase['default'])(get(this, 'propertyName')));
    }
  });
});