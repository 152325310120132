define('ember-local-settings/services/local-settings', ['exports', 'ember', 'ember-local-settings/mixins/settings-interface', 'ember-local-settings/local-settings-interface'], function (exports, _ember, _emberLocalSettingsMixinsSettingsInterface, _emberLocalSettingsLocalSettingsInterface) {
  var Service = _ember['default'].Service;
  var on = _ember['default'].on;
  var testing = _ember['default'].testing;

  /**
   * A service implementing a settings interface.
   *
   * @class LocalSettingsService
   * @extends Ember.Service
   * @uses SettingsInterfaceMixin
   */
  exports['default'] = Service.extend(_emberLocalSettingsMixinsSettingsInterface['default'], {
    /**
     * On initialization, read in the config property and use it to set any unset
     * properties. This config property is meant to be injected from an
     * initializer to allow easier configuration of the service, e.g. from the
     * environment.
     */
    initConfig: on('init', function () {
      var _this = this;

      var configProps = ['adapter', 'serializer', 'prefix'];
      var config = this.get('config') || defaultConfig();
      _ember['default'].A(configProps).forEach(function (prop) {
        if (config[prop] && !_this.get(prop)) {
          _this.set(prop, config[prop]);
        }
      });
    }),

    /**
     * Create a "branch" -- a futher prefixed settings interface. The prefix is
     * appended to this service's prefix.
     *
     * @param prefix
     * @returns {*} settings interface
     */
    createBranch: function createBranch(prefix) {
      return _emberLocalSettingsLocalSettingsInterface['default'].create({
        serializer: this.get('serializer'),
        adapter: this.get('adapter'),
        prefix: this.get('prefix') + prefix
      });
    }
  });

  function defaultConfig() {
    // When testing, set the default to local memory so unit/integration tests
    // (that run without initializers) don't need to do it themselves.
    var adapter = testing ? 'local-memory' : 'local-storage';
    return { adapter: adapter, serializer: 'json' };
  }
});