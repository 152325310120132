define('ember-burger-menu/animations/slide-shrink', ['exports', 'ember-burger-menu/animations/base'], function (exports, _emberBurgerMenuAnimationsBase) {
  exports['default'] = _emberBurgerMenuAnimationsBase['default'].extend({
    animation: 'slide-shrink',

    outlet: function outlet(open, width, right) {
      return {
        marginLeft: open && !right ? width + 'px' : 0,
        marginRight: open && right ? width + 'px' : 0
      };
    }
  });
});