define('ember-form-for/components/form-errors', ['exports', 'ember', 'ember-form-for/templates/components/form-errors'], function (exports, _ember, _emberFormForTemplatesComponentsFormErrors) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    layout: _emberFormForTemplatesComponentsFormErrors['default'],
    tagName: '',

    limitedErrors: computed('errors.[]', 'maxErrors', function () {
      var errors = get(this, 'errors');
      var maxErrors = get(this, 'maxErrors');

      if (maxErrors) {
        return errors.slice(0, maxErrors);
      }

      return errors;
    }),

    joinedErrorClasses: computed('errorClasses', function () {
      return (get(this, 'errorClasses') || []).join(' ');
    })
  });
});