define('ember-form-for/components/form-label', ['exports', 'ember', 'ember-form-for/templates/components/form-label'], function (exports, _ember, _emberFormForTemplatesComponentsFormLabel) {
  var Component = _ember['default'].Component;

  var FormLabelComponent = Component.extend({
    tagName: 'label',
    layout: _emberFormForTemplatesComponentsFormLabel['default'],

    attributeBindings: ['for', 'form']
  });

  FormLabelComponent.reopenClass({
    positionalParams: ['label']
  });

  exports['default'] = FormLabelComponent;
});