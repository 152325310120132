define('ember-form-for/components/form-fields/month-field', ['exports', 'ember-form-for/components/form-fields/text-field', 'ember-form-for/utils/date'], function (exports, _emberFormForComponentsFormFieldsTextField, _emberFormForUtilsDate) {
  exports['default'] = _emberFormForComponentsFormFieldsTextField['default'].extend({
    control: 'one-way-month',

    serializeValue: function serializeValue(value) {
      if (value instanceof Date) {
        return (0, _emberFormForUtilsDate.toMonthString)(value);
      }

      return value;
    },

    deserializeValue: function deserializeValue(value) {
      if (value != null) {
        return new Date(value);
      }

      return value;
    }
  });
});