define('ember-form-for/components/form-fields/select-field', ['exports', 'ember', 'ember-form-for/templates/components/form-fields/select-field'], function (exports, _ember, _emberFormForTemplatesComponentsFormFieldsSelectField) {
  var Component = _ember['default'].Component;
  var set = _ember['default'].set;

  var SelectFieldComponent = Component.extend({
    tagName: '',
    layout: _emberFormForTemplatesComponentsFormFieldsSelectField['default'],

    control: 'one-way-select',

    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    }
  });

  SelectFieldComponent.reopenClass({
    positionalParams: ['propertyName', 'options']
  });

  exports['default'] = SelectFieldComponent;
});