define('ember-hammertime/components/link-component', ['exports', 'ember', 'ember-hammertime/mixins/touch-action'], function (exports, _ember, _touchAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LinkComponent = _ember.default.LinkComponent;

  exports.default = LinkComponent.reopen(_touchAction.default);
});