define('ember-burger-menu/animations/scale-up', ['exports', 'ember-burger-menu/animations/base'], function (exports, _emberBurgerMenuAnimationsBase) {
  exports['default'] = _emberBurgerMenuAnimationsBase['default'].extend({
    animation: 'scale-up',

    outlet: function outlet(open, width, right) {
      return {
        transform: open ? right ? 'translate3d(-' + width + 'px, 0, 0)' : 'translate3d(' + width + 'px, 0, 0)' : ''
      };
    },

    menu: function menu(open, width) {
      return {
        transform: open ? '' : 'translate3d(0, 0, -' + width + 'px)'
      };
    }
  });
});