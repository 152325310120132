define('ember-burger-menu/animations/index', ['exports', 'ember', 'ember-require-module'], function (exports, _ember, _emberRequireModule) {
  exports['default'] = getAnimation;
  var assert = _ember['default'].assert;
  var typeOf = _ember['default'].typeOf;
  var isEmpty = _ember['default'].isEmpty;

  var ANIMATION_PATH = 'ember-burger-menu/animations';
  var ANIMATION_ITEM_PATH = ANIMATION_PATH + '/menu-item';

  function getAnimation(animation, itemAnimation) {
    var AnimationClass = undefined;

    if (typeOf(animation) === 'class' && animation.__isAnimation__) {
      AnimationClass = animation;
    } else {
      AnimationClass = (0, _emberRequireModule['default'])(ANIMATION_PATH + '/' + animation);
      assert('The animation \'' + animation + '\' could not be found.', AnimationClass);
    }

    if (!isEmpty(itemAnimation)) {
      var MenuItemMixin = (0, _emberRequireModule['default'])(ANIMATION_ITEM_PATH + '/' + itemAnimation);

      assert('The item animation \'' + itemAnimation + '\' could not be found.', MenuItemMixin);

      return AnimationClass.extend(MenuItemMixin);
    }

    return AnimationClass;
  }
});