define('ember-form-for/components/form-controls/reset', ['exports', 'ember-form-for/components/form-controls/button', 'ember-invoke-action'], function (exports, _emberFormForComponentsFormControlsButton, _emberInvokeAction) {
  var _slice = Array.prototype.slice;
  exports['default'] = _emberFormForComponentsFormControlsButton['default'].extend({
    type: 'reset',

    click: function click(e) {
      e.preventDefault();
      _emberInvokeAction.invokeAction.apply(undefined, [this, 'reset'].concat(_slice.call(arguments)));
    }
  });
});