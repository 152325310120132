define('ember-burger-menu/computed/style-for', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = computedStyleFor;
  var computed = _ember['default'].computed;

  function computedStyleFor(type) {
    return computed('state.{styles,open,width,position}', 'index', function () {
      var state = this.get('state');
      var args = state.getProperties(['open', 'width', 'position']);

      if (type === 'menuItem') {
        args.index = this.get('index');
      }

      return state.get('styles').generateCSSFor(type, args);
    });
  }
});