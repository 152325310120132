define('ember-burger-menu/animations/rotate-out', ['exports', 'ember-burger-menu/animations/base'], function (exports, _emberBurgerMenuAnimationsBase) {
  exports['default'] = _emberBurgerMenuAnimationsBase['default'].extend({
    animation: 'rotate-out',

    outlet: function outlet(open, width, right) {
      return {
        transform: open ? right ? 'translate3d(-' + width + 'px, 0, 0)' : 'translate3d(' + width + 'px, 0, 0)' : ''
      };
    }
  });
});