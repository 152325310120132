define('ember-local-settings/adapters/local-memory', ['exports', 'ember'], function (exports, _ember) {
  var NAME_KEY = _ember['default'].NAME_KEY;
  var computed = _ember['default'].computed;

  /**
   * Adapter that stores values in an in-memory hash.
   *
   * @class LocalMemoryAdapter
   * @exten extends Ember.Object
   */
  var LocalMemoryAdapter = _ember['default'].Object.extend({
    /**
     * The storage hash
     *
     * @property storage
     * @private
     * @type Object
     */
    storage: computed(function () {
      return {};
    }),

    /**
     * Get a value
     *
     * @param {String} key
     * @returns {String} value
     */
    getValue: function getValue(key) {
      return this.get('storage')[key];
    },

    /**
     * Set a value
     *
     * @param {String} key
     * @param {String} value
     * @return {Void}
     */
    setValue: function setValue(key, value) {
      this.get('storage')[key] = value;
    },

    /**
     * Delete a value
     *
     * @param {String} key
     * @return {Void}
     */
    deleteValue: function deleteValue(key) {
      delete this.get('storage')[key];
    },

    /**
     * Get count of stored values
     *
     * @returns {Integer}
     */
    getItemCount: function getItemCount() {
      return this.getKeys().length;
    },

    /**
     * Get the key of the stored value at an index
     *
     * @param {Integer} index
     * @returns {String}
     */
    getKeyAt: function getKeyAt(index) {
      return this.getKeys()[index];
    },

    /**
     * Get an array of the keys of all the stored values
     *
     * @returns {Array}
     */
    getKeys: function getKeys() {
      return Object.keys(this.get('storage'));
    }
  });
  LocalMemoryAdapter[NAME_KEY] = "LocalMemoryLocalSettingsAdapter";

  exports['default'] = LocalMemoryAdapter;

  LocalMemoryAdapter.reopenClass({
    clearStorage: function clearStorage() {
      LocalMemoryAdapter.prototype.storage = {};
    }
  });
});