define('ember-form-for/components/form-fields/date-field', ['exports', 'ember', 'ember-form-for/components/form-fields/text-field', 'ember-form-for/utils/date'], function (exports, _ember, _emberFormForComponentsFormFieldsTextField, _emberFormForUtilsDate) {
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = _emberFormForComponentsFormFieldsTextField['default'].extend({
    control: 'one-way-date',

    serializeValue: function serializeValue(value) {
      if (value instanceof Date) {
        return (0, _emberFormForUtilsDate.toDateString)(value);
      }

      return value;
    },

    deserializeValue: function deserializeValue(value) {
      if (isEmpty(value)) {
        return null;
      }

      return new Date(value);
    }
  });
});