define("ember-local-settings/serializers/noop", ["exports", "ember"], function (exports, _ember) {
  var NAME_KEY = _ember["default"].NAME_KEY;

  /**
   * A serializer that passes values through as-is. Can only be used with
   * adapters that accept any type, or in environments where only strings need
   * to be stored.
   *
   * @class NoopSerializer
   * @extends Ember.Object
   */
  var NoopSerializer = _ember["default"].Object.extend({
    /**
     * Serialize a value
     *
     * @param value
     * @returns {*}
     */
    serialize: function serialize(value) {
      return value;
    },

    /**
     * Deserializer a value
     *
     * @param value
     * @returns {*}
     */
    deserialize: function deserialize(value) {
      return value;
    }
  });
  NoopSerializer[NAME_KEY] = "NoopLocalSettingsSerializer";

  exports["default"] = NoopSerializer;
});