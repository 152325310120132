define('ember-burger-menu/components/bm-menu-item', ['exports', 'ember', 'ember-burger-menu/templates/components/bm-menu-item', 'ember-burger-menu/computed/style-for', 'ember-burger-menu/utils/is-fastboot'], function (exports, _ember, _emberBurgerMenuTemplatesComponentsBmMenuItem, _emberBurgerMenuComputedStyleFor, _emberBurgerMenuUtilsIsFastboot) {
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberBurgerMenuTemplatesComponentsBmMenuItem['default'],
    classNames: ['bm-menu-item'],
    attributeBindings: ['style'],

    state: null,

    menuItems: null,
    dismissOnClick: false,
    style: (0, _emberBurgerMenuComputedStyleFor['default'])('menuItem').readOnly(),

    index: computed('menuItems.[]', function () {
      if ((0, _emberBurgerMenuUtilsIsFastboot['default'])()) {
        return -1;
      }

      var $item = this.$();
      return $item ? $('.bm-menu-item', $item.closest('.bm-menu')).index($item) : -1;
    }).readOnly(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      run.scheduleOnce('afterRender', this.get('menuItems'), 'addObject', this.get('elementId'));
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      run.scheduleOnce('afterRender', this.get('menuItems'), 'removeObject', this.get('elementId'));
    },

    click: function click() {
      this._super.apply(this, arguments);

      if (this.get('dismissOnClick')) {
        this.get('state.actions').close();
      }
    }
  });
});