define('ember-burger-menu/animations/scale-down', ['exports', 'ember-burger-menu/animations/base'], function (exports, _emberBurgerMenuAnimationsBase) {
  exports['default'] = _emberBurgerMenuAnimationsBase['default'].extend({
    animation: 'scale-down',

    outlet: function outlet(open, width) {
      return {
        transform: open ? 'translate3d(0, 0, -' + width + 'px)' : ''
      };
    }
  });
});