define('ember-local-settings/adapters/cookie', ['exports', 'ember', 'js-cookie'], function (exports, _ember, _jsCookie) {
  var NAME_KEY = _ember['default'].NAME_KEY;
  var computed = _ember['default'].computed;

  /**
   * Adapter that stores values in cookies
   *
   * @class CookieAdapter
   * @extends Ember.Object
   */
  var CookieAdapter = _ember['default'].Object.extend({
    /**
     * Cookie expiry in days
     *
     * @property expires
     * @type Integer
     * @default 36500
     */
    expires: 36500,
    /**
     * Cookie path
     *
     * @property path
     * @type String
     * @default null
     */
    path: null,
    /**
     * Cookie domain
     *
     * @property domain
     * @type String
     * @default null
     */
    domain: null,
    /**
     * Cookie secure flag
     *
     * @property secure
     * @type Boolean
     * @default null
     */
    secure: null,

    /**
     * Options hash used when creating cookies
     *
     * @private
     * @type Object
     * @default {}
     */
    options: computed('expires', 'path', 'domain', 'secure', function () {
      var options = {};
      var props = this.getProperties(['expires', 'path', 'domain', 'secure']);
      _ember['default'].A(Object.keys(props)).forEach(function (key) {
        if (props[key]) {
          options[key] = props[key];
        }
      });
      return options;
    }),

    /**
     * Get a value
     *
     * @param {String} key
     * @returns {String} value
     */
    getValue: function getValue(key) {
      return _jsCookie['default'].get(key);
    },

    /**
     * Set a value
     *
     * @param {String} key
     * @param {String} value
     * @return {Void}
     */
    setValue: function setValue(key, value) {
      _jsCookie['default'].set(key, value, this.get('options'));
    },

    /**
     * Delete a value
     *
     * @param {String} key
     * @return {Void}
     */
    deleteValue: function deleteValue(key) {
      _jsCookie['default'].remove(key, this.get('options'));
    },

    /**
     * Get count of stored values
     *
     * @returns {Integer}
     */
    getItemCount: function getItemCount() {
      return this.getKeys().length;
    },

    /**
     * Get the key of the stored value at an index
     *
     * @param {Integer} index
     * @returns {String}
     */
    getKeyAt: function getKeyAt(index) {
      return this.getKeys()[index];
    },

    /**
     * Get an array of the keys of all the stored values
     *
     * @returns {Array}
     */
    getKeys: function getKeys() {
      return Object.keys(_jsCookie['default'].get());
    }
  });
  CookieAdapter[NAME_KEY] = "CookieLocalSettingsAdapter";

  exports['default'] = CookieAdapter;
});