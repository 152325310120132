define('ember-form-for/components/form-controls/submit', ['exports', 'ember', 'ember-async-button/components/async-button', 'ember-async-button/templates/components/async-button', 'ember-one-way-controls/-private/dynamic-attribute-bindings'], function (exports, _ember, _emberAsyncButtonComponentsAsyncButton, _emberAsyncButtonTemplatesComponentsAsyncButton, _emberOneWayControlsPrivateDynamicAttributeBindings) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;
  var alias = _ember['default'].computed.alias;

  var SubmitButton = _emberAsyncButtonComponentsAsyncButton['default'].extend(_emberOneWayControlsPrivateDynamicAttributeBindings['default'], {
    layout: _emberAsyncButtonTemplatesComponentsAsyncButton['default'],

    config: service('ember-form-for/config'),
    submit: alias('action'),
    'default': 'Submit',
    pending: 'Submitting...',

    NON_ATTRIBUTE_BOUND_PROPS: ['click'],

    init: function init() {
      this._super.apply(this, arguments);

      var type = get(this, 'type');
      var buttonClasses = get(this, 'config.' + type + 'Classes');
      var classNames = get(this, 'classNames');
      set(this, 'classNames', (classNames || []).concat(buttonClasses));
    }
  });

  SubmitButton.reopenClass({
    positionalParams: ['default']
  });

  exports['default'] = SubmitButton;
});