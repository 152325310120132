define('ember-math-helpers/helpers/imul', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.imul = imul;
  var Helper = _ember.default.Helper;
  function imul(params) {
    return Math.imul(params[0], params[1]);
  }

  exports.default = Helper.helper(imul);
});