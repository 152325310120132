define('ember-component-css/mixins/style-namespacing-extras', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    _componentIdentifier: computed({
      get: function get() {
        return (this._debugContainerKey || '').replace('component:', '');
      }
    }),

    _shouldAddNamespacedClassName: computed({
      get: function get() {
        return this.get('tagName') !== '' && this.get('componentCssClassName');
      }
    })
  });
});