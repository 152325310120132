define('ember-math-helpers/helpers/add', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.add = add;
  var Helper = _ember.default.Helper;
  function add(params) {
    return params.reduce(function (a, b) {
      return Number(a) + Number(b);
    });
  }

  exports.default = Helper.helper(add);
});